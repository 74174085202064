import React from "react"
import Page from "../../../components/page"
import ResponsiveImageGrid from "../../../components/responsive-image-grid"
import { themes } from "../../../styles/themes"
import paths from "../../../utils/paths"

const fromPurityToGrid = labels => [
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/from-purity-to/deep-throat-masks.jpg",
        aspectRatio: "803 / 563",
        title: labels.fromPurityToDeepThroatTitle,
        titleColor: "black",
        alt: labels.fromPurityToDeepThroatAlt,
        imgTitle: labels.deepThroatImgTitle
      },
      {
        type: "image",
        weight: 23.875,
        src: "/images/from-purity-to/deep-throat-detail.jpg",
        aspectRatio: "380 / 563",
        title: labels.fromPurityToDeepThroatTitle,
        subtitle: labels.fromPurityToDeepThroatDetailSubtitle,
        alt: labels.fromPurityToDeepThroatDetailAlt,
        imgTitle: labels.deepThroatImgTitle
      },
      {
        type: "image",
        weight: 23.875,
        src: "/images/from-purity-to/wake-up-for-make-up-collection.jpg",
        aspectRatio: "380 / 563",
        title: labels.fromPurityToWakeUpForMakeUpTitle,
        subtitle: labels.fromPurityToWakeUpForMakeUpCollectionSubtitle,
        alt: labels.fromPurityToWakeUpForMakeUpCollectionAlt,
        imgTitle: `${labels.wakeUpForMakeUpCollectionImgTitle}: ${labels.wakeUpForMakeUpImgTitle}, ${labels.reflectionsOfFantasiesImgTitle}, ${labels.imTheWildOneImgTitle}`
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 23.875,
        src: "/images/from-purity-to/reflections-of-fantasies-mirror.jpg",
        aspectRatio: "380 / 563",
        title: labels.fromPurityToReflectionOfFantasiesTitle,
        subtitle: labels.fromPurityToReflectionOfFantasiesMirrorSubtitle,
        alt: labels.fromPurityToReflectionsOfFantasiesMirrorAlt,
        imgTitle: labels.reflectionsOfFantasiesImgTitle
      },
      {
        type: "image",
        weight: 23.875,
        src: "/images/from-purity-to/wake-up-for-make-up-detail-lingerie.jpg",
        aspectRatio: "380 / 563",
        alt: labels.fromPurityToWakeUpForMakeUpDressingTableLingerieAlt,
        imgTitle: labels.wakeUpForMakeUpImgTitle
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/from-purity-to/wake-up-for-make-up-dressing-table.jpg",
        aspectRatio: "803 / 563",
        title: labels.fromPurityToWakeUpForMakeUpTitle,
        subtitle: labels.fromPurityToWakeUpForMakeUpDressingTableSubtitle,
        alt: labels.fromPurityToWakeUpForMakeUpDressingTableAlt,
        imgTitle: labels.wakeUpForMakeUpImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "html",
        weight: 27,
        html: (
          <div className="paragraph-div-centered-relatively from-purity-to-imagine-text-container">
            <p className="from-purity-to-imagine-text-language">
              {labels.fromPurityToImagineHR}
            </p>
            <p>{labels.fromPurityToImagineTextHR}</p>
            <p className="from-purity-to-imagine-text-motto">
              {labels.fromPurityToImagineTextMottoHR}
            </p>
          </div>
        )
      },
      {
        type: "image",
        weight: 43,
        src: "/images/from-purity-to/deep-throat-above.jpg",
        // imgClass: "from-purity-to-deep-throat-above-img",
        fit: "scale-down",
        alt: labels.fromPurityToDeepThroatAboveAlt,
        imgTitle: labels.deepThroatImgTitle,
        aspectRatio: "630 / 986"
      },
      {
        type: "html",
        weight: 27,
        html: (
          <div className="paragraph-div-centered-relatively from-purity-to-imagine-text-container">
            <p className="from-purity-to-imagine-text-language">
              {labels.fromPurityToImagineEN}
            </p>
            <p>{labels.fromPurityToImagineTextEN}</p>
            <p className="from-purity-to-imagine-text-motto">
              {labels.fromPurityToImagineTextMottoEN}
            </p>
          </div>
        )
      }
    ]
  }
]

export default function FromPurityTo({ labels, ...props }) {
  return (
    <Page
      {...props}
      labels={labels}
      title={labels.fromPurityTo}
      theme={themes(labels).ipsWhite(true)}
      description={labels.fromPurityToDescription}
      keywords={labels.fromPurityToKeywords}
      ogUrl={paths(labels).fromPurityTo}
      ogImage="/images/og-images/from-purity-to.jpg"
      headerPadding
      footerPadding
    >
      <ResponsiveImageGrid grid={fromPurityToGrid(labels)} margin />
    </Page>
  )
}
